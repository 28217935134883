import classNames from 'classnames';
import React from 'react';
import { slugify } from 'utils';

import styles from './heading.module.scss';
import AnchorIcon from './svg/anchor.inline.svg';

export const Heading = ({ className, tag = 'h1', size, children, id }) => {
  const wrapperClassName = classNames(
    styles.wrapper,
    { [styles.lg]: size === 'lg' },
    { [styles.md]: size === 'md' },
    { [styles.sm]: size === 'sm' },
    { [styles.xs]: size === 'xs' },
    className
  );

  return React.createElement(tag, { className: wrapperClassName, id }, children);
};

export const HeadingLandmark =
  (Tag, showAnchor = true) =>
  ({ children }) => {
    const getPlainText = (arr) => arr.reduce((acc, cur) => acc.concat(cur.props?.children ?? cur), '');
    const textContent = Array.isArray(children) ? getPlainText(children) : children;
    const id = slugify(textContent).replace(/\//g, '-').replace(/^\d+/g, '').replace(/^-*/g, '').replace(/-*$/g, '');
    return (
      <Tag className={styles.markHeading} id={id}>
        {children}
        {showAnchor && (
          <a className={'anchor-icon'} href={`#${id}`}>
            <AnchorIcon />
          </a>
        )}
      </Tag>
    );
  };
