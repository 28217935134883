import { SEO } from 'components/blocks/seo';
import { NotFound } from 'components/pages/404/not-found';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

const NotFoundPage = () => (
  <DefaultLayout>
    <NotFound />
  </DefaultLayout>
);

export default NotFoundPage;

export const Head = () => <SEO {...SEO_DATA['404']} />;
